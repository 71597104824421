nav.navbar {
    background: rgba(0,0,0,0.6);
    transition: 1s;
}

div.jumbotron {
    background-size: cover;
    background-position: center;
    min-height: calc(100vh);
    background-attachment: fixed;
    margin-bottom: 0;
}

div.jumbotron, div.carousel-caption {
    text-shadow: 0 0 20px #000000;
}

div.jumbotron svg {
    margin-left: 19px;
    margin-right: 19px;
    color: white;
}

div.jumbotron div.container {
    color: white;
    margin-top: 160px;
}

div.card-deck {
    margin: 0;
    margin-bottom: 15px;
}

div.card {
    color: black;
}

.card-img-overlay .card-text {
    background-color: rgba(0,0,0,0.5);
    padding: 2vw;
    border-radius: 8px;
    max-width: 50vw;
}

.main-text {
    margin-left: 8vw;
    margin-right: 8vw;
    text-align: justify;
    margin-bottom: 24px;
}

@media screen and (min-width: 950px) {
    div.card-deck {
        margin-left: 14vw !important;
        margin-right: 14vw !important;
    }
    .main-text, div.carousel {
        margin-left: 16vw;
        margin-right: 16vw;
    }
}

body {
    color: rgb(200,200,200);
    background-color: rgb(40,40,40);
    margin-top: 100px;
}

.jumbotron:first-of-type {
    margin-top: -100px;
 }

 canvas {
     position: absolute;
     top: 0;
 }

 div.card img {
     object-fit: cover;
 }

 div.card {
     margin: 15px;
 }

 .upper-margin {
    margin-top: 40px;
 }